import Script from 'next/script'

interface EHawkTalonLoadScriptProps {
	eHawkTalonLoaded: (res: string) => void
}

export function EHawkTalonLoadScript({ eHawkTalonLoaded }: EHawkTalonLoadScriptProps) {
	const handleEHawkTalon = async () => {
		await window.Talon.eHawkTalon()
		const tal = document.getElementById('talon6') as HTMLInputElement
		eHawkTalonLoaded(tal?.value)
	}

	return (
		<Script
			id="eHawkTalon"
			strategy="afterInteractive"
			onLoad={handleEHawkTalon}
			src="/scripts/EHawkTalon.js"
		/>
	)
}
